/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, MutationTree, Module } from 'vuex'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'

import {
  getFeeds,
  deleteById as deleteFeedById,
  getSchedulesByFeedId,
  createFeed,
  updateFeed,
  getFeedById,
} from '@/services/feedsDataProvider'
import {
  getSchedules,
  createFeedSchedule,
  getScheduleDownloadUrl,
  updateFeedSchedule,
  deleteById as deleteScheduleById,
  getActivityLogsByScheduleId,
  getScheduleById,
} from '@/services/schedulesDataProvider'
import {
  getConfigNotebookId as getConfigNotebookIds,
  getBindingDataset,
  getNotebookParameters,
} from '@/services/notebookDataProvider'
import { IFeed } from '@/types/feeds'
import { ISchedule } from '@/types/schedules'
import { ICacheState, IError } from '@/types/cache'
import { INotification } from '@/types/notification'
import { getUserSettings } from '@/services/userDataProvider'
import { getSourceName } from '@/utils/feedMapper'

const state: ICacheState = {
  cache: {
    feeds: [],
    schedules: [],
    notebooks: {},
    configNotebooks: undefined,
    schedulesByFeed: {},
    showLoader: true,
    token: undefined,
    refreshTokenExists: false,
    parametersLoader: false,
    notifications: [],
    notificationCounter: 0,
    scheduleUrls: {},
    editedFeed: undefined,
    editedSchedule: undefined,
    userSettings: undefined,
    authCode: undefined,
    bindingDatasets: {},
    requestFromLens: false,
    investmentItem: undefined,
  },
}

type GetFeedsFn = () => Promise<IFeed[]>
type GetSchedulesFn = () => Promise<ISchedule[]>

const PROVIDERS: Record<string, GetFeedsFn | GetSchedulesFn> = {
  feeds: getFeeds,
  schedules: getSchedules,
}

const mutations: MutationTree<ICacheState> = {
  SET_CACHE(state, { key, data }) {
    state.cache[key] = data
    state.cache.showLoader = false
  },
  REMOVE_FEED(state, feedId) {
    // Remove feed from stored cache
    state.cache.feeds = state.cache.feeds.filter(
      (obj: { id: number }) => obj.id !== feedId
    )
    // Remove related schedule to that feed
    state.cache.schedules = state.cache.schedules.filter(
      (obj: { feed_id: number }) => obj.feed_id !== feedId
    )
  },
  REMOVE_SCHEDULE(state, scheduleId) {
    state.cache.schedules = state.cache.schedules.filter(
      (obj: { id: number }) => obj.id !== scheduleId
    )
  },
  SHOW_LOADER(state, showLoader) {
    state.cache.showLoader = showLoader
  },
  ADD_FEED(state, feed) {
    state.cache.feeds.push(feed)
  },
  ADD_SCHEDULE(state, schedule) {
    state.cache.schedules.push(schedule)
  },
  SET_NOTEBOOK_PARAMETERS(state, { notebookId, notebookParameters }) {
    if (!isEmpty(notebookParameters)) {
      state.cache.notebooks[notebookId] = notebookParameters
    }
    state.cache.parametersLoader = false
  },
  SET_CONFIG_NOTEBOOKS(state, configNotebooks) {
    if (configNotebooks) {
      state.cache.configNotebooks = configNotebooks
    }
  },
  SET_SCHEDULES(state, { feedId, schedules }) {
    state.cache.schedulesByFeed[feedId] = schedules
  },
  SET_TOKEN(state, token) {
    state.cache.token = token
    sessionStorage.setItem('FEED_MANAGER_TOKEN', token)
  },
  SET_REFRESH_TOKEN_CHECK(state, refreshTokenExists) {
    state.cache.refreshTokenExists = refreshTokenExists
    sessionStorage.setItem('FEED_MANAGER_REFRESH_TOKEN', refreshTokenExists)
  },
  SET_AUTH_CODE(state, authCode) {
    state.cache.authCode = authCode
  },
  SET_LOAD_PARAMETERS(state) {
    state.cache.parametersLoader = true
  },
  ADD_NOTIFICATION(state, notification) {
    state.cache.notifications.push(notification)
  },
  REMOVE_NOTIFICATION(state, notificationId) {
    state.cache.notifications.splice(notificationId, 1)
  },
  INCREMENT_COUNTER(state) {
    state.cache.notificationCounter = state.cache.notificationCounter + 1
  },
  SET_SCHEDULES_URL(state, { scheduleId, downloadUrls }) {
    state.cache.scheduleUrls[scheduleId] = downloadUrls
  },
  SET_EDITED_FEED(state, feed) {
    /* 
      This condition is done due to we are checking any cache update to load the modal with the feed data
      in the SchedulerModalWrapper component. With this shallow copy of the feed object we make sure that
      the editedFeed property will have a new value each time we open the modal, no matter what the previous 
      feed was.

      Otherwise, we set the value we are receiving. For the case when we can to clean this property,
      we use undefined.
    */
    state.cache.editedFeed = feed ? { ...feed } : feed
  },
  UPDATE_FEED(state, updatedFeed) {
    const feedIndex = state.cache.feeds.findIndex(
      (feed) => feed.id === updatedFeed.id
    )
    if (feedIndex !== -1) {
      state.cache.feeds.splice(feedIndex, 1, updatedFeed)
    }
  },
  SET_EDITED_SCHEDULE(state, schedule) {
    state.cache.editedSchedule = schedule
  },
  UPDATE_SCHEDULE(state, updateSchedule) {
    const scheduleIndex = state.cache.schedules.findIndex(
      (schedule) => schedule.id === updateSchedule.id
    )

    if (scheduleIndex !== -1) {
      state.cache.schedules.splice(scheduleIndex, 1, updateSchedule)
    }
  },
  SET_USER_SETTINGS(state, settings) {
    state.cache.userSettings = settings
  },
  SET_BINDING_DATASET(state, { investmentId, dataset_id }) {
    state.cache.bindingDatasets[investmentId] = dataset_id
  },
  SET_REQUEST_FROM_DIRECT(state, requestFromLens) {
    state.cache.requestFromLens = requestFromLens
  },
  SET_INVESTMENT_ITEM(state, investmentItem) {
    state.cache.investmentItem = investmentItem
  },
}

const actions: ActionTree<ICacheState, any> = {
  async fetchData(
    { commit, state, dispatch },
    {
      key,
      refresh = false,
      showLoader = true,
    }: { key: string; refresh: boolean; showLoader: boolean }
  ) {
    if (showLoader) {
      commit('SHOW_LOADER', true)
    }

    if (!isEmpty(state.cache[key]) && !refresh) {
      commit('SHOW_LOADER', false)
      return state.cache[key]
    }

    const data = await PROVIDERS[key]()

    commit('SET_CACHE', { key, data })
    await dispatch('userSettings')

    return data
  },
  async deleteFeed({ commit }, id: number) {
    const isFeedDeleted = await deleteFeedById(id)

    if (isFeedDeleted) {
      commit('REMOVE_FEED', id)
    }
    return isFeedDeleted
  },
  async deleteSchedule({ commit }, id: number) {
    const isScheduleDeleted = await deleteScheduleById(id)

    if (isScheduleDeleted) {
      commit('REMOVE_SCHEDULE', id)
    }
    return isScheduleDeleted
  },
  async getSchedules({ commit }, feedId: number) {
    if (state.cache.schedulesByFeed[feedId]) {
      return state.cache.schedulesByFeed[feedId]
    }
    const schedules = await getSchedulesByFeedId(feedId)
    commit('SET_SCHEDULES', {
      feedId,
      schedules,
    })
    return schedules
  },
  async getConfigNotebookId({ commit }, titles: string[]) {
    if (state.cache.configNotebooks) {
      return state.cache.configNotebooks
    }

    const configNotebooks = await getConfigNotebookIds(titles)
    commit('SET_CONFIG_NOTEBOOKS', configNotebooks)

    return configNotebooks
  },
  async getNotebookParameters({ commit }, notebookId: string) {
    if (!isEmpty(state.cache.notebooks[notebookId])) {
      return state.cache.notebooks[notebookId]
    }

    commit('SET_LOAD_PARAMETERS')
    const parameters = await getNotebookParameters(notebookId)

    if (parameters) {
      commit('SET_NOTEBOOK_PARAMETERS', {
        notebookId,
        notebookParameters: parameters,
      })

      return parameters
    }
    return []
  },
  async getActivityLogs(_, idsObject) {
    const { feedId, scheduleId } = idsObject

    const activityLogs = await getActivityLogsByScheduleId(feedId, scheduleId)
    return activityLogs
  },
  async createFeed({ commit }, feedObject) {
    try {
      const { feed_id } = await createFeed(feedObject)

      const newFeed = {
        ...feedObject,
        id: Number(feed_id),
        name: feedObject.job_name,
        is_config_notebook: true,
        last_update: new Date(Date.now()).toISOString().slice(0, -5) + 'Z',
      }

      commit('ADD_FEED', newFeed)

      return {
        feed: newFeed,
        hasDuplicateName: false,
      }
    } catch (e: any) {
      const error = e.response.data as IError
      const hasDuplicateName = error?.detail?.status?.code === '422000'

      return {
        feed: undefined,
        hasDuplicateName,
      }
    }
  },
  async editFeed({ state, commit }, { feedId, requestBody }) {
    try {
      const { feed_id = undefined } = await updateFeed(feedId, requestBody)
      const { job_name: name, notebook_parameters } = requestBody

      const updatedFeed = {
        name,
        id: Number(feed_id),
        notebook_id: state.cache.editedFeed?.feedInfo.notebook_id,
        user_id: state.cache.editedFeed?.feedInfo.user_id,
        is_config_notebook: true,
        notebook_parameters,
        last_update: new Date(Date.now()).toISOString().slice(0, -5) + 'Z',
      }

      commit('UPDATE_FEED', updatedFeed)

      return {
        feed: updatedFeed,
        hasDuplicateName: false,
      }
    } catch (e: any) {
      const error = e.response.data as IError
      const hasDuplicateName = error?.detail?.status?.code === '422000'

      return {
        feed: undefined,
        hasDuplicateName,
      }
    }
  },
  async createSchedule({ state, commit }, { feedId, scheduleInfo }) {
    try {
      const schedule = await createFeedSchedule(feedId, scheduleInfo)

      if (schedule.id) {
        if (!isEmpty(state.cache.schedules)) {
          commit('ADD_SCHEDULE', schedule)
        }

        return {
          schedule,
          hasDuplicateName: false,
        }
      }
    } catch (e: any) {
      const error = e.response.data as IError
      const hasDuplicateName = error?.detail?.status?.code === '422000'

      return {
        schedule: undefined,
        hasDuplicateName,
      }
    }
  },
  async downloadSchedule({ commit, state }, scheduleId: number) {
    if (state.cache.scheduleUrls[scheduleId]) {
      return state.cache.scheduleUrls[scheduleId]
    } else {
      const downloadUrls = await getScheduleDownloadUrl(scheduleId)

      if (!isEmpty(downloadUrls)) {
        commit('SET_SCHEDULES_URL', { scheduleId, downloadUrls })
      }
      return downloadUrls
    }
  },
  async updateSchedule({ commit }, { feedId, scheduleId, scheduleInfo }) {
    try {
      const schedule = await updateFeedSchedule(
        feedId,
        scheduleId,
        scheduleInfo
      )

      if (schedule.id) {
        commit('UPDATE_SCHEDULE', schedule)

        return {
          schedule,
          hasDuplicateName: false,
        }
      }
    } catch (e: any) {
      const error = e.response.data as IError
      const hasDuplicateName = error?.detail?.status?.code === '422000'

      return {
        schedule: undefined,
        hasDuplicateName,
      }
    }
  },
  async getSelectedSchedule(
    { commit },
    { scheduleId = undefined, feedId = undefined }
  ) {
    if (!scheduleId && !feedId) {
      commit('SET_EDITED_SCHEDULE', undefined)
      return
    }

    const schedule = await getScheduleById(feedId, scheduleId)

    if (!schedule) {
      return undefined
    }

    const {
      id,
      feed_id,
      name,
      start_day,
      running_time,
      timezone,
      frequency,
      next_run_time,
    } = schedule as ISchedule

    commit('SET_EDITED_SCHEDULE', {
      id,
      feed_id,
      name,
      start_day,
      running_time,
      timezone,
      frequency,
      next_run_time,
    })
  },
  async updateDataset({ commit, state }, { type, investmentId }) {
    // For an 'Untitled' dataset with an empty string as the dataset_id,
    // this validation is necessary to store it in the cache.
    if (!isUndefined(state.cache.bindingDatasets[investmentId])) {
      return state.cache.bindingDatasets[investmentId]
    }

    const { dataset_id } = await getBindingDataset(type, investmentId)

    if (!isUndefined(dataset_id)) {
      commit('SET_BINDING_DATASET', { investmentId, dataset_id })
    }
    return dataset_id
  },
  pushNotification({ commit, dispatch, state }, newNotification) {
    const newCounter = state.cache.notificationCounter + 1

    const notification: INotification = {
      id: `${Date.now()}-${newCounter}`,
      ...newNotification,
    }

    notification.dismissed = setTimeout(() => {
      dispatch('removeNotification', notification.id)
    }, 5000)

    commit('ADD_NOTIFICATION', notification)
    commit('INCREMENT_COUNTER', notification)
  },
  removeNotification({ commit }, index) {
    const notificationId = state.cache.notifications.findIndex(
      ({ id }: any) => id === index
    )
    if (notificationId >= 0) {
      const dismissed = state.cache.notifications[notificationId].dismissed
      if (dismissed) {
        clearTimeout(dismissed)
      }
      state.cache.notifications[notificationId].dismissed = undefined
      commit('REMOVE_NOTIFICATION', notificationId)
    }
  },
  async getSelectedFeed({ commit }, feedId = undefined) {
    if (!feedId) {
      commit('SET_EDITED_FEED', undefined)
      return
    }

    const feed = await getFeedById(feedId)

    if (!feed) {
      return undefined
    }

    const { id, notebook_id, user_id, name, notebook_parameters } = feed
    const selectedFeed = {
      ...notebook_parameters,
      investment_object_type:
        getSourceName(notebook_parameters.investment_object_type) ??
        notebook_parameters.investment_object_type,
      name,
      feedInfo: {
        id,
        notebook_id,
        name,
        user_id,
      },
    }

    commit('SET_EDITED_FEED', selectedFeed)
    return selectedFeed
  },
  async userSettings({ state, commit }) {
    if (!isEmpty(state.cache.userSettings)) {
      return state.cache.userSettings
    }

    const settings = await getUserSettings()
    commit('SET_USER_SETTINGS', settings)

    return settings
  },
  setAuthCode({ commit }, authCode) {
    commit('SET_AUTH_CODE', authCode)
  },
  setRequestFromDirect({ commit }, requestFromLens) {
    commit('SET_REQUEST_FROM_DIRECT', requestFromLens)
  },
  setInvestmentItem({ commit }, investmentItem) {
    commit('SET_INVESTMENT_ITEM', investmentItem)
  },
}

const cacheModule: Module<ICacheState, unknown> = {
  state,
  mutations,
  actions,
}

export default cacheModule
